.text-input {
    border: 1px solid #cacccd;
    font-size: $font-size-medium;
    font-weight: 300;
    width: 120px;
    height: 40px;
    padding: $s-size;
}

.text-input__error {
    @extend .text-input;
    border: 1px solid red;
}

.select {
    @extend .text-input;
    width: 260px;
    padding: 1px;
    margin-left: 10px;
}

.select__table {
    @extend .select;
    width: 150px;
    height: 26px;
    font-size: $font-size-small;
    margin-left: 5px;
}

.textarea {
    @extend .text-input;
    height: 10rem;
}

.formdata {
    display: flex;
    align-items: center;
}

.formdata__padding {
    @extend .formdata;
    padding-top: 1.5rem;
}
.formdata__label {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.text-input__counter {
    border: 1px solid #cacccd;
    font-size: $font-size-small;
    font-weight: 300;
    width: 70px;
    height: 20px;
    padding: $s-size;
}

.text-input__time {
    border: 1px solid #cacccd;
    font-size: $font-size-small;
    font-weight: 300;
    width: 130px;
    height: 20px;
    padding: $s-size;
}

.text-input__scale {
    border: 1px solid #cacccd;
    font-size: $font-size-small;
    font-weight: 300;
    width: 80px;
    height: 20px;
    padding: $s-size;
}

.text-input__date {
    border: 1px solid #cacccd;
    font-size: $font-size-small;
    font-weight: 300;
    width: 140px;
    height: 20px;
    padding: $s-size;
}

.text-input__payroll {
    border: 1px solid #cacccd;
    font-size: $font-size-small;
    font-weight: 300;
    width: 65px;
    height: 20px;
    padding: $s-size;
}

.text-input__name {
    border: 1px solid #cacccd;
    font-size: $font-size-small;
    font-weight: 300;
    width: 100px;
    height: 20px;
    padding: $s-size;
}

.text-input__margin {
    margin-left: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
