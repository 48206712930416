.table-layout {
    display: inline-block;
}

.styled-table {
    border-collapse: collapse;
    margin: 10px 20px;
    font-size: 0.9em;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table__fixed {
    table-layout: fixed;
}

.styled-table thead tr {
    background-color: $dark-blue;
    color: #ffffff;
    text-align: left;
    font-weight: 400;
}

.styled-table th,
.styled-table td {
    padding: 3px 20px;
    text-align: center;
}

.narrow-table td {
    padding: 2px 2px 2px 2px;
}

.styled-table__wrap {
    word-wrap: break-word;
    width: 500px;
}

.styled-table tbody tr {
    border-bottom: 1px solid $med-grey;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f1f1f1;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid $dark-blue;
}

.override-cell {
    color: red;
}

.datepicker {
    width: 100%;
    width: 500px;
}
