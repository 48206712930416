.menu {
    background: $light-grey;
}

.menu__content {
    background: $dark-blue;
    min-width: 250px;
    width: 300px;
    height: 80vh;
    // align-items: center;
    flex: 0 1 300px;
}

.menu__title {
    display: flex;
    justify-content: flex-start;
    text-decoration: none;
    align-items: center;
    list-style: none;
    height: 50px;
    padding-top: 10px;
    color: $light-grey;
    font-size: $font-size-medium;
    font-weight: 400;
}

.menu__title__active {
    color: $yellow;
}

// .menu__title a {
//     color: $med-grey;
//     text-decoration: none;
//     display: flex;
//     border-radius: 4px;
//     align-items: center;
//     width: 95%;
//     height: 100%;
//     font-size: 18px;
//     font-weight: 400;
// }

// .menu__title a:hover {
//     color: $light-grey;
// }

// .menu__title a:focus {
//     color: $light-grey;
// }

.flex__container {
    display: flex;
}

.flex__report {
    flex: 1;
    padding: 0px 0 0 20px;
    // margin: 0 0 0 20px;
}
