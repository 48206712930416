.header {
    background: $dark-blue;
    padding-top: $s-size;
}

.photo {
    height: 100px;
    width: 110px;
}

.header__content {
    align-items: center;
    display: flex;
    padding: $s-size 0;
}

.header__title {
    color: $light-grey;
    font-size: $font-size-large;
    text-decoration: none;
    h1 {
        margin: 0;
    }
    padding: 0 $xl-size;
    margin-left: $xl-size;
}

.header_title_active {
    color: $yellow;
    border-bottom: 3px solid $light-grey;
}

.padding {
    padding-left: $s-size;
}

.xl__padding {
    padding-left: $xl-size;
}
