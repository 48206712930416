// Colors
$dark-grey: #333;
$reg-grey: #666;
$blue: #1c88bf;
$dark-blue: #012d5b;
$medium-blue: #00509c;
$light-grey: #f7f7f7;
$med-grey: #bebebe;
$grey: #888;
$dark-red: #8b0000;
$yellow: #ffcc00;
// Font Size
$font-size-large: 1.8rem;
$font-size-medium: 1.6rem;
$font-size-small: 1.4rem;
// Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$xxl-size: 6.4rem;
$desktop-breakpoint: 45rem;
// underline styles
$duration: 0.2s;
$distance: 8px;
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
