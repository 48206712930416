.welcome {
    display: flex;
    justify-content: center;
    width: 100rem;
}

.welcome__photo {
    height: 39rem;
    width: 43rem;
}

.welcome__title {
    color: $dark-red;
    font-weight: 600;
    font-size: 8rem;
    padding-bottom: 20px;
}
