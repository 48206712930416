.button {
    background-color: $dark-blue;
    color: white;
    border-radius: 5px;
    font-size: $font-size-medium;
    padding: 10px;
}

.button:disabled {
    background-color: $med-grey;
}

.button--link {
    background: none;
    border: none;
    color: $blue;
    display: inline-block;
    font-size: $font-size-large;
    font-weight: 300;
    line-height: 1;
    text-decoration: none;
    padding-right: 10px;
}

.button--group {
    display: flex;
    justify-content: right;
    max-width: 800px;
}

.table-button {
    background-color: $dark-blue;
    color: white;
    border-radius: 5px;
    font-size: $font-size-small;
    padding: 5px;
    width: 80px;
    margin: 4px;
}

.table-button__small {
    background-color: $dark-blue;
    color: white;
    border-radius: 5px;
    font-size: $font-size-small;
    padding: 2px;
    width: 30px;
    margin: 4px;
    margin-right: 10px;
}

.table-button:disabled {
    background-color: $med-grey;
}

.table-button__small:disabled {
    background-color: $med-grey;
}
