.amplify-tabs {
    display: none;
}

// .amplify-field {
//     font-size: 40px;
// }

.amplify-select,
.amplify-input,
.amplify-textarea {
    font-size: 14px;
}

.amplify-button {
    font-size: 16px;
}

.amplify-button[data-size='small'] {
    font-size: 14px;
    color: $dark-blue;
}

.amplify-button[data-variation='primary'] {
    background-color: $medium-blue;
}

.amplify-button[data-variation='primary']:hover {
    background-color: $dark-blue;
}
