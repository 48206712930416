.reportdate {
    display: flex;
    align-items: center;
}

.reportdate__title {
    padding-right: 1.5rem;
}

.reportdate__button {
    padding-left: 1.5rem;
    justify-content: flex-end;
}
